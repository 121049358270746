import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, StaticQuery, graphql } from 'gatsby';

const render = ({menu}) => (
  <>
    <Helmet>
      <script src={withPrefix("/web-components/ug-header.js")}></script>
    </Helmet>
    <div id="header-breakpoint"></div>
    {console.log(menu)}
    <ug-header>
        {menu.map((item,i) => 
         item.submenu !== null && item.submenu.length > 0 ?
        <>
            <uofg-dropdown-menu key={i}>
              <button data-for="menu-button">{item.title}</button>
              <ul data-for="menu-content">
                <li key={i}><a key={i} href={item.path}>{item.title}</a></li>
                {item.submenu.map ((subitem,j)=>
                  <li key={j}> <a key={j}href={subitem.path}>{subitem.title}</a></li>
                )}
              </ul>
            </uofg-dropdown-menu>
            
        </>:<a key={i} href={item.path}>{item.title}</a>
        )}

    </ug-header>
  </>
)

const query = graphql`
  query {
    site {
      siteMetadata {
        menu {
          title
          path
          submenu {
            title
            path
          }
        }
      }
    }
  }
`

export default function Header() {
  return <StaticQuery query={query} render={({site}) => render(site.siteMetadata)} />
}

